@tailwind base;
@tailwind components;
@tailwind utilities;

// See https://github.com/tailwindlabs/tailwindcss/discussions/2213#discussioncomment-4905042
@layer utilities {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}
